import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: false },
    component: () => import("../views/Pages/Login")
  },
  {
    path: "/crm-decode",
    name: "DCRM Decode",
    meta: { requiresAuth: false },
    component: () => import("../views/Pages/DCRM/Decode")
  },
  {
    path: "/401",
    name: "Error 401",
    meta: { requiresAuth: false },
    props: route => ({ query: route.query.brandID }),
    component: () => import("../views/401")
  },
  // {
  //   path: "/order/login",
  //   name: "OrderLogin",
  //   component: () => import("../views/Pages/order/Login"),
  //   meta: { isOrder: true }
  //   // props: route => ({ query: route.query.brandID })
  // },
  {
    path: "/",
    meta: { requiresAuth: true },
    component: () => import("../views/Layouts/Container"),
    props: route => ({ query: route.query.brandID }),
    children: [
      // {
      //   path: "/",
      //   name: "HomeChat",
      //   component: () => import("../views/Chat.vue")
      // },
      {
        path: "/chat",
        name: "Chat",
        component: () => import("../views/Chat.vue")
      },
      {
        path: "/",
        name: "Order",
        redirect: "/chat",
        component: () => import("../views/Pages/order/Index"),
        // meta: { isOrder: true },
        children: [
          {
            path: "/:id",
            name: "Detail",
            component: () => import("../views/Pages/order/Detail"),
            props: route => ({ query: route.query.brandID })
            // meta: { isOrder: true }
          }
        ]
      }
    ]
  },
  {
    path: "/decode/:sessionId",
    name: "Decode",
    meta: { requiresAuth: false },
    component: () => import("../views/Pages/Decode")
  },
  {
    path: "/AccessDenied",
    name: "PermissionDenied",
    meta: { requiresAuth: false },
    component: () => import("../views/Pages/PermissionDenied")
  },

  // {
  //   path: "/Home",
  //   name: "Container",
  //   component: () => import("../views/Layouts/Container")
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("../views/About.vue"),
  // },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes
});

export default router;
