import Vue from "vue";
// import axios from "axios"
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  dcrm: {
    brandId: "",
    chatRoomId: ""
  }
});

export const mutations = {
  UPDATE_TEMP_DCRM: (state, payload) => {
    state.dcrm = { ...payload };
  }
};

export const actions = {
  setTempDCRM(context, payload) {
    context.commit("UPDATE_TEMP_DCRM", payload);
  }
};
export const getters = {
  getTempDCRM: state => {
    return state.dcrm;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
