import Vue from "vue";
// import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  global: {
    activePanel: 1,
    screenSize: {
      width: 0,
      height: 0
    },
    isMobile: false
  }
});

export const mutations = {
  UPDATE_ACTIVE_PANEL: (state, payload) => {
    state.global.activePanel = payload;
  },
  UPDATE_SCREEN_SIZE: (state, payload) => {
    state.global.screenSize = payload;
  },
  UPDATE_IS_MOBILE: (state, payload) => {
    state.global.isMobile = payload;
  }
};

export const actions = {
  setActivePanel(context, payload) {
    context.commit("UPDATE_ACTIVE_PANEL", payload);
  },
  setScreenSize(context, payload) {
    context.commit("UPDATE_SCREEN_SIZE", payload);
    let isMobile = payload.width < 768;
    context.commit("UPDATE_IS_MOBILE", isMobile);
  }
};
export const getters = {
  getActivePanel: state => {
    return state.global.activePanel;
  },
  getScreenSize: state => {
    return state.global.screenSize;
  },
  getIsMobile: state => {
    return state.global.isMobile;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
